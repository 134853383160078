@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700");

html,
body,
#root,
.App {
    height: 100%;
}

main {
    height: 95%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: "Poppins", sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

button#startButton {
    width: 120px;
    height: 50px;
    border-radius: 50px;
    border: none;
    background-color: #ff5a5f;
    color: #fff;
}

h2 {
    font-weight: medium;
}

.credit {
    font-weight: 300;
    font-style: italic;
}

footer p {
    display: flex;
    justify-content: center;
    width: 100vw;
}

#space100 {
    height: 100px;
}

.field {
    width: 300px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid black;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.field.incorrect input {
    color: #ff5a5f;
    font-style: italic;
    font-weight: bold;
}

.field.incorrect {
    color: #ff5a5f;
    font-style: italic;
    font-weight: bold;
}

.field.correct input {
    color: #4caf50;
    font-style: italic;
    font-weight: bold;
}

button#check {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: #ff5a5f;
    color: #fff;
}

input {
    width: 250px;
    height: 40px;
    border-radius: 50px;
    border: none;
    color: black;
    text-align: center;
    font-size: 1em;
    background-color: transparent;
}

input:focus {
    outline: none;
}
